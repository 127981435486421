.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .3s ease-out;
  opacity: 1;
}

.fade-exit {
  transition: opacity .3s ease-in;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}
