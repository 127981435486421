@import url('https://fonts.googleapis.com/css?family=Satisfy&display=swap');

.app {
  display: flex;
}

.app-main {
  position: relative;
  flex: 1;
  background-color: #ffffff;
  color: #7ea2a0;
  min-height: 100vh;
}

.app-content {
  padding: 1rem 2rem;
}
